<template>
	<div class="tf-create-item tf-section">
		<div class="themesflat-container" style="margin-top: 100px;" v-if="owner_of_collection === true">
			<div class="btn btn-success" @click="active_tab = 'collection'">Collection</div>
			<div class="btn btn-success" @click="active_tab = 'roadmap'">Roadmap</div>
			<div class="row" v-show="active_tab == 'collection'">
				<div class="col-xl-3 col-lg-4 col-md-6 col-12">
					<div class="sc-card-profile text-center">
						<div class="card-media">
							<img id="profileimg" v-if="form.logo == null || form.logo == 'null' || form.logo == undefined" src="assets/images/avatar/avata_profile.jpg" alt="Image">
							<img id="profileimg" v-else :src="route_server_image + '/storage/' + form.logo" alt="Image">
						</div>
					<div id="upload-profile">
						<a href="#" class="btn-upload">
							Upload New Photo </a>
							<input id="tf-upload-img" type="file" name="profile" @change="profil_pic_preview()">
					</div>
					<a href="#" class="btn-upload style2" @click="delete_profile_pic()">
						Delete</a>
					</div>
				</div>
				<div class="col-xl-9 col-lg-8 col-md-12 col-12">
					<div class="form-upload-profile">
						<h4 class="title-create-item">Choice your Cover image</h4>
						<div class="option-profile clearfix">
							<form action="#" class="background-pic-form" enctype="multipart/form-data">
                <span class="background-pic-delete" @click="delete_background_pic()">X</span>
								<label class="uploadFile" id="background-image-label" v-if="form.banner == null || form.banner == 'banner_1' || form.banner == 'banner_2' || form.banner == 'null' || form.banner == undefined" style="background-image: url('assets/images/avatar/avata_profile.jpg');">
									<input type="file" class="inputfile form-control" id="background-image-input" name="file" @change="background_pic_preview()">
								</label>
								<label class="uploadFile" id="background-image-label" v-else :style="'background-image: url(' + route_server_image + '/storage/' + form.banner + ');background-position: center;background-repeat: no-repeat;background-size: cover;'">
									<input type="file" class="inputfile form-control" id="background-image-input" name="file" @change="background_pic_preview()">
								</label>
							</form>
							<div class="image default-background-choice" @click="form.banner = 'banner_1';$forceUpdate()" :style="form.banner == 'banner_1' ? 'background: #5142FC;' : ''">
								<img src="assets/images/backgroup-secsion/option1_bg_profile.jpg" alt="">
							</div>
							<div class="image style2 default-background-choice" @click="form.banner = 'banner_2';$forceUpdate()" :style="form.banner == 'banner_2' ? 'background: #5142FC;' : ''">
								<img src="assets/images/backgroup-secsion/option2_bg_profile.jpg" alt="">
							</div>
						</div>
						<form class="form-profile" id="collection_form" @submit="form_verification">
							<div class="form-infor-profile">
								<div class="info-account">
									<h4 class="title-create-item">Collection info</h4>                                    
										<fieldset>
											<h4 class="title-infor-account">Description</h4>
											<textarea tabindex="4" name="description" v-model="form.description" rows="5"></textarea>
										</fieldset>
										<fieldset>
											<h4 class="title-infor-account">Mint date</h4>
											<input type="text" name="mint_date" v-model="form.mint_date" placeholder="dd/mm/yyyy">
										</fieldset> 
										<fieldset>
											<h4 class="title-infor-account">Supply</h4>
											<input type="number" name="supply" v-model="form.supply" min="0">
										</fieldset>  
								</div>
								<div class="info-social">
									<h4 class="title-create-item">{{ url_data }}</h4>                                    
										<fieldset>
											<h4 class="title-infor-account">Why you should buy ?</h4>
											<textarea tabindex="4" name="why_you_should_buy" v-model="form.why_you_should_buy" rows="5"></textarea>
										</fieldset>
										<fieldset>
											<h4 class="title-infor-account">Mint price</h4>
											<input type="number" name="mint_price" v-model="form.mint_price" min="0" onkeyup="if(this.value<0){this.value= this.value * -1}">
										</fieldset>
								</div> 
							</div>
							<fieldset>
								<input type="hidden" name="collection_id" v-model="form.collection_id">
							</fieldset>
							<button class="tf-button-submit mg-t-15" type="submit">
								Update Collection
							</button>
						</form>
					</div>
				</div>
			</div>
			<div class="row" v-show="active_tab == 'roadmap'">
				<div class="col-xl-3 col-lg-4 col-md-6 col-12">
					<h1>Roadmap Edition</h1>
				</div>
				<div class="col-xl-9 col-lg-8 col-md-12 col-12">
					<table class="table">
						<thead>
							<tr>
								<th scope="col" style="text-align: center;">Title</th>
								<th scope="col" style="text-align: center;">Date</th>
								<th scope="col" style="text-align: center;">Description</th>
								<th scope="col" style="text-align: center;">Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(roadmap_element, key) in form.roadmap" :key="key">
								<td>{{ roadmap_element.title }}</td>
								<td>{{ roadmap_element.date }}</td>
								<td v-if="roadmap_element.description != undefined"><nl2br tag="span" :text="roadmap_element.description"/></td>
								<td v-if="roadmap_element.description == undefined"></td>
								<td style="text-align: center;"><i class="fas fa-pen" style="font-size: 15px;cursor: pointer;padding-top: 2%;" @click="edit_roadmap(roadmap_element)"></i> <i class="fas fa-times" style="font-size: 15px;cursor: pointer;padding-top: 2%;" @click="delete_roadmap(roadmap_element)"></i></td>
							</tr>
							<tr>
								<td><input type="text" v-model="new_roadmap.title"></td>
								<td><input type="text" v-model="new_roadmap.date"></td>
								<td><textarea tabindex="4" v-model="new_roadmap.description" rows="1"></textarea></td>
								<td style="text-align: center;"><i style="font-size: 15px;cursor: pointer;padding-top: 8%;" class="fas fa-plus-circle" @click="add_roadmap"></i></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div v-show="modal_edit_roadmap == true">
      <transition name="modal">
        <div class="modal-mask">

          <div class="modal-body" style="width: 75%">
            <h2 style="padding-top: 30px;">Roadmap Edition</h2>
            <fieldset style="padding-top: 30px">
							<h4 class="title-infor-account">Title</h4>
							<input type="text" v-model="roadmap_edition.title">
						</fieldset> 
						<fieldset>
							<h4 class="title-infor-account">Date</h4>
							<input type="text" v-model="roadmap_edition.date">
						</fieldset> 
						<fieldset>
							<h4 class="title-infor-account">Description</h4>
							<textarea tabindex="4" v-model="roadmap_edition.description" rows="1"></textarea>
						</fieldset>  
            <div class="modal-footer">
              <button class="tf-button-submit mg-t-15" @click="modal_edit_roadmap = false" style="background-color: red;border-color: red;">
								Close
							</button>
              <button class="tf-button-submit mg-t-15" @click="save_roadmaps">
								Update
							</button>
            </div>
          </div>

        </div>
      </transition>
    </div>
		</div>
		<div class="themesflat-container" style="margin-top: 100px;"  v-show="owner_of_collection === false">
			<h2 style="padding-top: 30px; text-align: center;">You are not allowed to be there..</h2>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
// import NftCard from '@/components/NftCard.vue'
// import CollectionActivity from '@/components/CollectionActivity.vue'

import axios from 'axios';

import toastr from 'toastr'
import {getWalletAddress} from '@/libs/Wallet.js'

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {

  name: 'CollectionEdit',
  data: function () {
	
	return {
		
		url_data: this.$route.params.id,
		active_tab: 'collection',
		my_nft: {},
		form: {
			description: '',
			why_you_should_buy: '',
			supply: 0,
			mint_date: '',
			mint_price: 0,
			collection_id: '',
			logo: '',
			banner: '',
			roadmap: [],
			owner: '',
    },
    new_roadmap: {
			title: '',
			date: '',
			description: '',
		},
		roadmap_edition: {},
    user: {},
		route_server: process.env.VUE_APP_ROOT_API,
		route_server_image: process.env.VUE_APP_ROOT_BO,
		modal_edit_roadmap: false,
		owner_of_collection: false,
	}
  },
  props: {
   
  },
  methods: {

    switch_tab: function(new_tab) {

      this.active_tab = new_tab;
    },

    profil_pic_preview : function(){

      var component_vue = this;

      var file = $('#tf-upload-img').get(0).files[0];

      if(file){
        component_vue.form.logo = null;

        var reader = new FileReader();
        
        reader.onload = function(){
          $("#profileimg").attr("src", reader.result);
        }

        reader.readAsDataURL(file);
      }

    },

    background_pic_preview : function(){

      var component_vue = this;

      var file = $('#background-image-input').get(0).files[0];

      if(file){
        component_vue.form.banner = null;

        var reader = new FileReader();

        reader.onload = function(){
          $("#background-image-label").attr("style","background-image:url(" + reader.result + ");background-position: center;background-repeat: no-repeat;background-size: cover;");
        }

        reader.readAsDataURL(file);

      }

    },

    delete_background_pic : function (){

      var component_vue = this;

      $('#background-image-input').val('');
      $("#background-image-label").attr("style","background-image:url('assets/images/avatar/avata_profile.jpg');");
      component_vue.form.banner = null;

    },

    delete_profile_pic : function (){

      var component_vue = this;

      $('#tf-upload-img').val('');
      $("#profileimg").attr("src", 'assets/images/avatar/avata_profile.jpg');
      component_vue.form.logo = null;

    },

    form_verification : function (e){

      e.preventDefault();

      var component_vue = this;

      var info_send = new FormData();

      var form_info = JSON.parse(JSON.stringify(component_vue.form));

      for (const [key, value] of Object.entries(form_info)) {
        info_send.append(key, value);
      }

      if(form_info.banner != 'banner_1' && form_info.banner != 'banner_2' && $('#background-image-input').get(0).files[0] != undefined) {
        info_send.delete('banner');
        info_send.append('banner', $('#background-image-input').get(0).files[0]);
      }

      if(form_info.logo == null && $('#tf-upload-img').get(0).files[0] != undefined) {
        info_send.delete('logo');
        info_send.append('logo', $('#tf-upload-img').get(0).files[0]);
      }

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/edit', info_send, config_axios).then(function(response) {

				if(response.data.response === true)
					toastr.success('Succes !')
				else
					toastr.success('Error...')
      });
    },

    add_roadmap: function(){

		var component_vue = this;

		axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/'+component_vue.form.collection_id+'/roadmap/add', component_vue.new_roadmap, config_axios).then(function(roadmap) {

				toastr.success('Succes !');

				component_vue.form.roadmap = roadmap.data;

				component_vue.new_roadmap = {
					'title': '',
					'date': '',
					'description': '',
				};
			});
		},

    edit_roadmap: function(roadmap){

			var component_vue = this;

			component_vue.modal_edit_roadmap = true;
			component_vue.roadmap_edition = roadmap;
    },

    delete_roadmap: function(roadmap){

			var component_vue = this;

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/'+component_vue.form.collection_id+'/roadmap/delete', roadmap.id, config_axios).then(function(roadmap) {

				toastr.success('Succes !');

				component_vue.form.roadmap = roadmap.data;
      });
    },

    save_roadmaps: function(){

			var component_vue = this;

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/'+component_vue.form.collection_id+'/roadmap/save', component_vue.form.roadmap, config_axios).then(function(roadmap) {

				toastr.success('Succes !');

				component_vue.form.roadmap = roadmap.data;
				component_vue.modal_edit_roadmap = false;
      });
    },

    get_collection: function(){

			var $this = this;

			var component_vue = this;

			$this.form.collection_id = component_vue.url_data;

			axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/collection/'+$this.url_data, config_axios).then(function(collection) {
				
				if($this.user.wallet === collection.data.owner){

					$this.owner_of_collection = true;					

					$this.form.why_you_should_buy = collection.data.why_you_should_buy;
					$this.form.description = collection.data.description;
					$this.form.mint_price = collection.data.mint_price;
					$this.form.mint_date = collection.data.mint_date;
					$this.form.supply = collection.data.supply;
					$this.form.logo = collection.data.logo;
					$this.form.banner = collection.data.banner;
					$this.form.collection_id = $this.url_data;
					$this.form.roadmap = collection.data.roadmap;
					$this.form.owner = collection.data.owner;
				}
			});
    }
  },
  created: function() {

		var $this = this;

		var component_vue = this;
	
	setTimeout(function() {
	
		if(getWalletAddress() == false) {
			component_vue.$root.$emit('show_modal',['connect_wallet',true]);

			component_vue.$root.$on('connection_wallet_accept',async () => {

				axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + getWalletAddress(), config_axios).then(function(user) {

					component_vue.user = user.data.user;
					component_vue.$forceUpdate();

					component_vue.get_collection();
				});
			});


		}
		else{

			axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + getWalletAddress(), config_axios).then(function(user) {

				component_vue.user = user.data.user;
				component_vue.$forceUpdate();

				component_vue.get_collection();
			});
		}
	
	}, 1000);
  },
}
</script>

